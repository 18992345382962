import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import user from "./modules/user";
import member from "./modules/member";
import merchant from "./modules/merchant"
import permission from "./modules/permission";
import createPersistedState from'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    member,
    merchant
  },
  getters,
  plugins: [createPersistedState()]
});

export default store;
