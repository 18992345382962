import axios from "axios";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router from "../router";
// 加载动画
let loading;
function startLoading() {
  loading = Loading.service({
    // lock: true,
    text: "拼命加载中"
  });
}
function endLoading() {
  loading.close();
}
// create an axios instance
const service = axios.create({
  //baseURL: '/api', // 测试环境
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    startLoading();
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
       config.headers["token"] = getToken();
    // }
    return config;
  },
  error => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  res => {
    endLoading();
    // if the custom code is not 20000, it is judged as an error.
    if(res.data.code == 401) {
      router.push({path:'/login'})
    } else if(res.data.code == 101) {
      return res;
    }else if(res.data.code !== 200) {
      Message({
        message: res.data.msg || res.data.message || "Error",
        type: "error",
        duration: 5 * 1000
      });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm(
      //     "You have been logged out, you can cancel to stay on this page, or log in again",
      //     "Confirm logout",
      //     {
      //       confirmButtonText: "Re-Login",
      //       cancelButtonText: "Cancel",
      //       type: "warning"
      //     }
      //   ).then(() => {
      //     store.dispatch("user/resetToken").then(() => {
      //       location.reload();
      //     });
      //   });
      // }
      return Promise.reject(
        new Error(res.data.error || res.data.msg || "Error")
      );
    }
     else {
      return res;
    }
  },
  error => {
    endLoading();
    // console.log("err" + error); // for debug
    Message({
      message: error,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

// 下载文件
const download = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 200000,
  responseType:'arraybuffer'
});
download.interceptors.request.use(
  config => {
    startLoading();
    return config;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);
download.interceptors.response.use(
  res => {
    endLoading();
    if (res.data.status) {
      Message({
        message: res.data.error || "Error",
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.data.error || "Error"));
    } else {
      return res;
    }
  },
  error => {
    endLoading();
    // console.log("err" + error); // for debug
    Message({
      message: error,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

window.$http = service;
window.$download = download;
export default service;
