import { constantRoutes } from "@/router";

// function hasPermission(roles, route) {
//   // 根据路由中的meta匹配角色
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.includes(role));
//   } else {
//     return true;
//   }
// }
// 过滤动态路由

// export function filterAsyncRoutes(routes, roles) {
//   const res = [];
//   routes.forEach(route => {
//     const tmp = { ...route };
//     if (hasPermission(roles, tmp)) {
//       if (tmp.children) {
//         tmp.children = filterAsyncRoutes(tmp.children, roles);
//       }
//       res.push(tmp);
//     }
//   });
//   return res;
// }
const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
const actions = {
  // generateRoutes({ commit }, roles) {
  //   return new Promise(resolve => {
  //     let accessedRoutes;
  //     if (roles.includes(1)) {
  //       accessedRoutes = asyncRoutes || [];
  //     } else {
  //       accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
  //     }
  //     commit("SET_ROUTES", accessedRoutes);
  //     resolve(accessedRoutes);
  //   });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
