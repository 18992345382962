const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userId: state => state.user.userId,
  storeId: state => state.user.storeId,
  userObject:state => state.user.userObject,
  memberId: state => state.member.memberId,
  userData: state => state.merchant.userData,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes
};
export default getters;
