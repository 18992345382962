import Vue from "vue";

// import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n

import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";
// import "default-passive-events";
import "@/icons"; // icon
import '@/assets/icons/iconfont/iconfont.css';//iconfont
import '@/assets/icons/iconfont/iconfont.js'//color iconfont
// import "@/permission"; // permission control
//   ,
//   
 import {
  submitValid,
  isConfirm,
  onInput,
  downFile,
  removeDot
 } from "./utils/index";
// import Print from "@/utils/print";
// Vue.use(Print); // 注册

Vue.filter("dataFormat", function(value, fmt) { //目前已知这个方法用于首页的时间格式转换 后续换成js引入
  let getDate = new Date(value);
  let o = {
    "M+": getDate.getMonth() + 1,
    "d+": getDate.getDate(),
    "h+": getDate.getHours(),
    "m+": getDate.getMinutes(),
    "s+": getDate.getSeconds(),
    "q+": Math.floor((getDate.getMonth() + 3) / 3),
    S: getDate.getMilliseconds()
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
});
// // set ElementUI lang to EN
// Vue.use(ElementUI, { locale });

// // 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI)

// // 全局获取完整图片路径方法
Vue.prototype.picsPath = function(picsName = "") {
  return picsName && picsName.includes("http")
    ? picsName
    : process.env.VUE_APP_BASE_API_IMG + "/file-service/file/" + picsName;
    // : "/file-service/file/" + picsName;
};
// Vue.config.productionTip = false;
 Vue.prototype.onInput = onInput; //输入框限制 小数点
 Vue.prototype.downFile = downFile;
 Vue.prototype.submitValid = submitValid; //表单验证
 Vue.prototype.isConfirm = isConfirm;
Vue.prototype.removeDot = removeDot;


router.beforeEach((to, from, next)=>{
  if(to.meta.title == "丽日购" || to.meta.title ==='丽日评价'){
    document.title = to.meta.title
  }
  next()
})
new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
