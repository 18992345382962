const state = {
    userData: "",
}

const mutations = {
    SET_USERDATA: (state, userData) => {
        state.userData = userData
    },
}

const actions = {

}

export default {
    state,
    mutations,
    actions
  }