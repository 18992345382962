const state = {
    memberId: "",
}

const mutations = {
    SET_MEMBERID: (state, memberId) => {
        state.memberId = memberId
    },
}

const actions = {

}

export default {
    state,
    mutations,
    actions
  }