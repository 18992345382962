/**
 * Created by PanJiaChen on 16/11/18.
 */
import axios from "axios";

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

// 限制输入，flag:1 -- 输入数字保留小数点，flag:0 -- 输入整数 dot 小数位
export function onInput(num, flag = 1, dot = 2) {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  if (flag) {
    //限制只能输入一个小数点
    if (str.indexOf(".") != -1) {
      var str_ = str.substr(str.indexOf(".") + 1);
      if (str_.indexOf(".") != -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
      }
    }
    //正则替换
    str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
    switch (dot) {
      case 2:
        str = str.replace(/\.\d{3,}$/, ""); // 小数点后只能输两位
        break;
      case 3:
        str = str.replace(/\.\d{4,}$/, ""); // 小数点后只能输三位
        break;
    }
  } else {
    str = str.replace(/[^\d^]+/g, ""); // 保留数字
  }
  return str;
}
// 文件下载
export function downFile(url, fileName, data,method) {
  $download({
    method: method || "post",
    url,
    data
  })
    .then(res => {
      if (res.data) {
        // console.log("文件下载")
        // console.log(res.data)
        const blob = new Blob([res.data]);
        //兼容IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
        } else {
          const a = document.createElement("a");
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = fileName + ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        }
      } else {
        this.$message.error("Error");
      }
    })

}

// 文件下载
export function downFile2(url, fileName, data,method) {
  $download({
    method: method || "post",
    url,
    data
  })
      .then(res => {
        if (res.data) {
          // console.log("文件下载")
          // console.log(res.data)
          const blob = new Blob([res.data]);
          //兼容IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName + ".xls");
          } else {
            const a = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            a.href = href;
            a.download = fileName + ".xls";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(href);
          }
        } else {
          this.$message.error("Error");
        }
      })

}

// 表单验证
export function submitValid(
  _this,
  form,
  handler,
  msg = "表单信息不完整（有误）"
) {
  form.validate(valid => {
    if (!valid) {
      _this.$message.warning(msg);
      return;
    }
    handler();
  });
}

// 是否确定弹框
export function isConfirm(_this, handler, msg = "确定要删除吗？") {
  _this
    .$confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
    .then(() => {
      handler();
    })

}

function encodeUTF8(s) {
  var i,
    r = [],
    c,
    x;
  for (i = 0; i < s.length; i++)
    if ((c = s.charCodeAt(i)) < 0x80) r.push(c);
    else if (c < 0x800) r.push(0xc0 + ((c >> 6) & 0x1f), 0x80 + (c & 0x3f));
    else {
      if ((x = c ^ 0xd800) >> 10 == 0)
        //对四字节UTF-16转换为Unicode
        (c = (x << 10) + (s.charCodeAt(++i) ^ 0xdc00) + 0x10000),
          r.push(0xf0 + ((c >> 18) & 0x7), 0x80 + ((c >> 12) & 0x3f));
      else r.push(0xe0 + ((c >> 12) & 0xf));
      r.push(0x80 + ((c >> 6) & 0x3f), 0x80 + (c & 0x3f));
    }
  return r;
}

// 字符串加密成 hex 字符串
export function sha1(s) {
  var data = new Uint8Array(encodeUTF8(s));
  var i, j, t;
  var l = (((data.length + 8) >>> 6) << 4) + 16,
    s = new Uint8Array(l << 2);
  s.set(new Uint8Array(data.buffer)), (s = new Uint32Array(s.buffer));
  for (t = new DataView(s.buffer), i = 0; i < l; i++)
    s[i] = t.getUint32(i << 2);
  s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
  s[l - 1] = data.length << 3;
  var w = [],
    f = [
      function() {
        return (m[1] & m[2]) | (~m[1] & m[3]);
      },
      function() {
        return m[1] ^ m[2] ^ m[3];
      },
      function() {
        return (m[1] & m[2]) | (m[1] & m[3]) | (m[2] & m[3]);
      },
      function() {
        return m[1] ^ m[2] ^ m[3];
      }
    ],
    rol = function(n, c) {
      return (n << c) | (n >>> (32 - c));
    },
    k = [1518500249, 1859775393, -1894007588, -899497514],
    m = [1732584193, -271733879, null, null, -1009589776];
  (m[2] = ~m[0]), (m[3] = ~m[1]);
  for (i = 0; i < s.length; i += 16) {
    var o = m.slice(0);
    for (j = 0; j < 80; j++)
      (w[j] =
        j < 16
          ? s[i + j]
          : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)),
        (t =
          (rol(m[0], 5) + f[(j / 20) | 0]() + m[4] + w[j] + k[(j / 20) | 0]) |
          0),
        (m[1] = rol(m[1], 30)),
        m.pop(),
        m.unshift(t);
    for (j = 0; j < 5; j++) m[j] = (m[j] + o[j]) | 0;
  }
  t = new DataView(new Uint32Array(m).buffer);
  for (var i = 0; i < 5; i++) m[i] = t.getUint32(i << 2);

  var hex = Array.prototype.map
    .call(new Uint8Array(new Uint32Array(m).buffer), function(e) {
      return (e < 16 ? "0" : "") + e.toString(16);
    })
    .join("");
  return hex;
}

export function removeDot(data) {
  return data[data.length - 1] === "." ? data.substr(0, data.length - 1) : data;
}
