<template>
  <div id="app">
    <router-view />
    <audio v-if='isPlay' src='./assets/music.mp3' ref='audio' autoplay ></audio>
  </div>
</template>

<script>
import store from "./store";
let notice = null; // 系统通知
let pnotice = null; // 页面通知
let type = 1; // 1 前台 2后台
let session = "";
export default {
  name: "App",
  data() {
    return {
      websock: null,
      storeId: "",
      router: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 10 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      isPlay: false //通知播放音乐
    };
  },
  created() {},
  watch: {
    $route(to, from) {
      if (
        from.path.includes("/login") ||
        (from.path == "/" && !to.path.includes("/login"))
      ) {
        if (this.$store.getters.roles[0] == 4) {
          this.init();
          this.initWebSocket();
        }
      }
      if (to.path.includes("/login")) {
        this.websocketclose();
      }
      this.router = this.$route.name;
      type = 1;
      if (this.router === "Orders_home_details") {
        notice ? notice.close() : "";
        pnotice ? pnotice.close() : "";
      }
    }
  },
  destroyed() {
    this.websocketclose();
  },
  methods: {
    randomWord(randomFlag, min, max) {
      let str = "",
        range = min,
        arr = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z"
        ];

      // 随机产生
      if (randomFlag) {
        range = Math.round(Math.random() * (max - min)) + min;
      }
      for (let i = 0; i < range; i++) {
        let pos = Math.round(Math.random() * (arr.length - 1));
        str += arr[pos];
      }
      return str;
    },
    initWebSocket() {
      // console.log('---')
      // debugger
      //建立连接
      //初始化weosocket
      //const wsuri = "ws://sms.填写您的地址.com/websocket/" + this.charId; //ws地址
      const wsuri = "ws://wechat.lirimall.com:8082/websocket";
      //建立连接
      this.websock = new WebSocket(wsuri);
      //连接成功
      this.websock.onopen = this.websocketonopen;
      //连接错误
      this.websock.onerror = this.websocketonerror;
      //接收信息
      this.websock.onmessage = this.websocketonmessage;
      //连接关闭
      this.websock.onclose = this.websocketclose;
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      // console.log("重连");
      that.timeoutnum = setTimeout(function() {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function() {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.websock.readyState == 1) {
          //如果连接正常
          // console.log("heartCheck");
          let data = {
            type: "test", //心跳，回复消息
            storeId: self.$store.getters.storeId
          };
          self.websock.send(JSON.stringify(data));
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function() {
          // console.log("超时关闭");
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    websocketonopen() {
      //连接成功事件
      //提示成功
      // console.log("连接成功");
      session = this.randomWord(true, 1, 50);
      let data = {
        type: "open", //链接成功第一条信息，
        value: session, //sessionid
        storeId: this.$store.getters.storeId
      };
      this.websock.send(JSON.stringify(data));
      //开启心跳
      this.start();
    },

    websocketonerror() {
      //连接失败事件
      //错误
      // console.log("WebSocket连接发生错误");
      //重连
      this.reconnect();
    },
    websocketclose() {
      //连接关闭事件
      //关闭
      // console.log("connection closed");
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      this.websock ? this.websock.close() : "";
      //重连
      // this.reconnect();
    },
    websocketonmessage(event) {
      //接收服务器推送的信息
      //打印收到服务器的内容
      // console.log(JSON.parse(event.data), "接收服务器推送的信息");
      if (event.data && event.data != "null") {
        this.judgeNotice(event.data);
      }
      //收到服务器信息，心跳重置
      this.reset();
    },
    websocketsend(msg) {
      //向服务器发送信息
      //数据发送
      this.websock.send(msg);
    },
    // 初始化
    init() {
      type = 1;
      window.addEventListener("blur", () => {
        type = 2;
      });
      window.addEventListener("focus", () => {
        type = 1;
      });
    },
    // 系统通知
    watchNotify(id) {
      let _this = this;
      Notification.requestPermission(function() {
        notice = new Notification("系统通知提醒:", {
          dir: "auto",
          lang: "hi",
          tag: "testTag",
          body: "您有新订单啦"
        });
        notice.onclose = () => {
           _this.closeMusic()
          // notice = null;
          // console.log("Notification - close");
        };
        notice.onclick = () => {
           _this.closeMusic()
          notice = null;
          window.close();
          // window.open(
          //   "https://wechat.lirimall.com:9443/#/transactions/orders/orders_home_details?id=" +
          //     id
          // );
          window.open(
            process.env.VUE_APP_BASE_API +"/#/transactions/orders/orders_home_details?id=" +
              id
          );
        };
      });
    },
    // 页面通知
    globalNotice(id) {
      let _this = this;
      pnotice = this.$notify({
        dangerouslyUseHTMLString: true,
        duration: 0,
        message: `<div style='width:100%;'><div class='flx-row ali-c'><i class='el-icon-bell mr-10' style='font-size:40px'></i><div>
    <strong style='font-size:16px;'>系统通知提醒</strong><div>您有新订单了</div></div>
    </div><div style='text-align:right'>去查看</div></div>`,
        onClick: function() {
          _this.closeMusic()
          _this.$router.push({
            name: "Orders_home_details",
            query: { id: id }
          });
          // this.close();
          store.commit("user/SET_ORDER", false);
        },
        onClose: function() {
           _this.closeMusic()
          pnotice = null;
          // console.log("notify - close");
          store.commit("user/SET_ORDER", false);
        }
      });
    },
    // 判断用哪种通知
    judgeNotice(id) {
      this.isPlay = true
      // console.log(notice, pnotice, "notice");
      if (this.router === "Orders_home_details") {
        notice ? notice.close() : "";
        pnotice ? pnotice.close() : "";
      } else {
        if (type == 1 && this.router != "print") {
          if (pnotice) {
            return;
          }
          this.globalNotice(id);
        }
        if (type == 2 && this.router != "print") {
          this.watchNotify(id);
        }
      }
    },
    closeMusic(){
      // let music = this.$refs.audio
      // music.pause()
      // music.currentTime = 0
      this.isPlay = false
    }
  }
};
</script>
<style lang="scss" scoped>
#app >>> .el-table .cell{
  overflow: visible;
}
</style>
